import React, { useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import ShortFormInput from 'atomic-design/organisms/short-contact-form/short-form-input';
import ShortFormTextarea from 'atomic-design/organisms/short-contact-form/short-form-text-area';
import Button from 'atomic-design/atoms/button';
import { Form, ButtonContainer, ErrorContainer, FormTitle } from './styles';

const ShortContactForm = ({ onSubmit, contactEmail }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const initialState = {
    name: '',
    email: '',
    message: '',
  };

  const [values, setValues] = useState(initialState);

  const { name, email, message } = values;

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const resetFields = () => {
    setValues(initialState);
  };

  const getErrorValues = obj => {
    return Object.keys(obj).reduce((a, c) => ((a[`${'error_'}${c}`] = obj[c]), a), {});
  };

  const sendGTMEvent = (err = false) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `contact_${err ? 'error' : 'message'}`,
      ...(err ? getErrorValues(values) : values),
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios({
        method: 'post',
        url: process.env.GATSBY_GETFORM_URL,
        data: new FormData(e.target),
      });
      sendGTMEvent();
      onSubmit();
      resetFields();
    } catch (e) {
      sendGTMEvent(true);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Form name="contact" onSubmit={handleSubmit}>
      <div>
        <FormTitle> {"Let's come together to assist you and your team."}</FormTitle>
        <ShortFormInput
          type="text"
          name="name"
          value={name}
          onChange={handleChange}
          label="Name "
          required
        />
        <ShortFormInput
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          label="E-mail "
          required
        />
        <ShortFormTextarea
          type="text"
          name="message"
          value={message}
          onChange={handleChange}
          label="What can we do for you? *"
          placeholder="Tell us what can we help you with..."
          required
        />
        {error && (
          <ErrorContainer>
            Error sending message. Please write us to{' '}
            <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
          </ErrorContainer>
        )}
        <ButtonContainer>
          <Button type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Send'}
          </Button>
        </ButtonContainer>
      </div>
    </Form>
  );
};

ShortContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  contactEmail: PropTypes.string.isRequired,
};

export default ShortContactForm;
