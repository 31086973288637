import styled from 'styled-components';

export const ImgContainer = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }

  img {
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    object-fit: cover;
    height: 100%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    height: 100vh;

    div {
      height: 100vh;
    }
  }
`;

export const PageHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const PageHeaderTextContainer = styled.section`
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: ${({ theme }) => `${theme.newTheme.spacing(50)} 0 ${theme.newTheme.spacing(20)} 0`};

  p {
    color: ${({ theme }) => theme.newTheme.color.white};
  }

  b {
    display: block;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 70%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    height: auto;
  }
`;

export const PageHeaderSubtitle = styled.h1`
  font-size: ${({ theme }) => theme.newTheme.font.title1.size};
  line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
  margin: -3px 0 0 0;
  color: ${({ theme }) => theme.newTheme.color.white};

  u {
    text-decoration: none;
    display: block;
  }

  b {
    background: ${({ theme }) => theme.newTheme.color.gradient.background.tertiary.main};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: ${({ theme }) => theme.newTheme.font.weight.light};
  }

  p {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.light};
    margin: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    font-size: ${({ theme }) => theme.newTheme.font.title2.size};
    line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  }

  @media only screen and (max-height: 700px) {
    font-size: ${({ theme }) => theme.newTheme.font.title2.size};
    line-height: ${({ theme }) => theme.newTheme.font.title2.lineHeight};
  }
`;

export const PageHeaderDescription = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  margin-top: ${({ theme }) => theme.newTheme.spacing(10)};
  width: 90%;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(12)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    line-height: ${({ theme }) => theme.lineHeight.secondary};
    width: 100%;
  }
`;

export const Triangle = styled.div`
  z-index: 0;
  border-top: ${({ theme }) => `5.106rem solid ${theme.newTheme.color.primary.main}`};
  border-right: 100vw solid transparent;
`;

export const StyledDiv = styled.div`
  p:first-of-type:first-letter {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
    padding-right: inherit;
    padding-top: inherit;
    float: left;
  }
`;
