import React from 'react';
import PropTypes from 'prop-types';

import { Container, TextArea, Label } from './styles';

const ShortFormTextarea = ({ label, type, name, value, onChange, required, placeholder }) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <TextArea
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        required={required}
      />
    </Container>
  );
};

ShortFormTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ShortFormTextarea.defaultProps = {
  required: false,
};
export default ShortFormTextarea;
