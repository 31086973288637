import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 30%;
`;

export const ErrorContainer = styled.p`
  color: ${({ theme }) => theme.colors.error};
`;

export const FormTitle = styled.h5`
  margin: 0 0 ${({ theme }) => theme.newTheme.spacing(12)};
  font-size: ${({ theme }) => theme.newTheme.font.title5.size};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
  letter-spacing: 0em;
  text-align: center;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
  }
`;

export const Form = styled.form`
  display: flex;
  padding: ${({ theme }) => theme.newTheme.spacing(15)};
  margin-bottom: ${({ theme }) => theme.spacing.longSM};
  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding: ${({ theme }) => theme.spacing.largest};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.large};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.large};
  }
`;
