import styled from 'styled-components';

export const BoxFormContainer = styled.div`
  width: 100%;
  padding: 0 !important;
  display: flex;
  justify-content: center;
`;

export const BoxForm = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  border-radius: ${({ theme }) => theme.radius.main};
  background-color: ${({ theme }) => theme.colors.transparent};
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(40)};
  min-height: ${({ theme }) => theme.newTheme.spacing(200)};

  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  backdrop-filter: blur(9.9px);
  -webkit-backdrop-filter: blur(9.7px);

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding-bottom: 0;
    min-height: auto;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(20)};
    width: 60%;
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
    min-height: auto;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(20)};
    padding-bottom: 0;
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }

  img {
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    object-fit: cover;
    height: 100%;
  }

  margin-top: ${({ theme }) => theme.newTheme.spacing(32)};
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(32)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    height: 100vh;

    div {
      height: 100vh;
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin-top: ${({ theme }) => theme.newTheme.spacing(20)};
    padding-bottom: 0;
  }
`;

export const TitleWrapper = styled.span`
  text-align: center;
  margin: 0 auto;
  width: 100%;
  div {
    padding-top: 0;
  }
  h1 {
    margin: ${({ theme }) => theme.newTheme.spacing(4)} 0;
    color: ${({ theme }) => theme.newTheme.color.primary.main};
  }
  h2 {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 100%;
    p {
      color: ${({ theme }) => theme.newTheme.color.pitchBlack};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
      line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
      b {
        display: block;
        font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
        width: ${({ theme }) => theme.newTheme.spacing(263)};
      }
    }
  }
  p {
    text-align: center;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.newTheme.spacing(263)};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(10)};
    margin: ${({ theme }) => theme.newTheme.spacing(10)} 0 0 0;
    h2 {
      p {
        font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
        line-height: ${({ theme }) => theme.newTheme.font.title1.lineHeight};
        b {
          font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
          width: 100%;
        }
      }
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(10)};
    h2 {
      p {
        font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
        line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
        text-align: left;
        b {
          font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
          width: 100%;
        }
      }
    }
    h1 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: ${({ theme }) => theme.newTheme.spacing(4)} 0 ${({ theme }) => theme.newTheme.spacing(3)};
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    section {
      padding: ${({ theme }) => theme.newTheme.spacing(4)} 0;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  p,
  span {
    color: ${({ theme }) => theme.newTheme.color.grey.main};
  }
  h3 {
    span:nth-child(1) {
      background: ${({ theme }) => theme.newTheme.color.gradient.text};
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      margin-left: 0;
      margin-bottom: ${({ theme }) => theme.newTheme.spacing(8)};
    }
  }
  p:nth-child(2) {
    color: ${({ theme }) => theme.fonts.colors.secondary};
  }
  span:nth-child(2) {
    margin-top: ${({ theme }) => theme.spacing.large};
  }
  span:nth-child(2),
  span:nth-child(3) {
    color: ${({ theme }) => theme.fonts.colors.secondary};
  }
  p span:nth-child(1) {
    color: ${({ theme }) => theme.fonts.colors.secondary};
    @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
      margin-left: ${({ theme }) => theme.spacing.smallest};
    }
    @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
      margin-left: 0;
    }
  }
  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(4)};
    span:nth-child(1) {
      margin-bottom: ${({ theme }) => theme.spacing.large};
      margin-left: ${({ theme }) => theme.spacing.smallest};
    }
    span:nth-child(2),
    span:nth-child(3) {
      display: inline !important;
    }
    span:nth-child(3) {
      margin-left: ${({ theme }) => theme.spacing.smallest};
    }
  }
`;
