import React from 'react';
import PropTypes from 'prop-types';

import { Container, Input, Label } from './styles';

const ShortFormInput = ({
  label,
  required,
  type,
  name,
  value,
  onChange,
  placeholder,
  disabled,
}) => {
  const labelText = required && label ? `${label}*` : label;
  return (
    <Container>
      <Label htmlFor={name}>{labelText}</Label>
      <Input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
      />
    </Container>
  );
};

ShortFormInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

ShortFormInput.defaultProps = {
  required: false,
  placeholder: '',
  disabled: false,
};

export default ShortFormInput;
