import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import theme from 'styles/theme';
import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import PageSection from 'atomic-design/atoms/page-section';
import ButtonGradient from 'atomic-design/atoms/button-gradient';
import MainContainer from 'atomic-design/atoms/main-container';
import {
  PageHeaderContainer,
  PageHeaderDescription,
  PageHeaderSubtitle,
  PageHeaderTextContainer,
  ImgContainer,
} from 'styles/pages/landing-page-styles';
import richTextOptions from 'common/rich-text-options';
import { renderLineBreak } from 'common/string-helpers';
import RichTextWrapper from 'atomic-design/organisms/rich-text-wrapper';
import LetUsAboutSection from 'atomic-design/organisms/let-us-about-section';
import { parseFaqsList } from 'common/transformers';
import FaqsTag from '../components/core/faqs-tags';
import FaqSection from 'atomic-design/organisms/faq-section';
import Image from 'core/image';
import useIsMobile from 'hooks/useIsMobile';
import HeaderAndForm from '../components/atomic-design/organisms/landing-headers/header-and-form';

const landingPage = ({ pageContext }) => {
  landingPage.propTypes = {
    pageContext: PropTypes.instanceOf(Object).isRequired,
  };
  const faqList = pageContext.faq && parseFaqsList(pageContext.faq.items);
  const isMobile = useIsMobile('mobile');
  const formLandingStyle = pageContext.layout && pageContext.layout.includes('with-form');
  const showMenu = pageContext.showMenu;

  const urlToRedirect =
    pageContext.pageHeaderData.metatagTitle === 'AWS Infrastructure'
      ? 'https://meetings.hubspot.com/nanlabs/aws-architecture-review'
      : '/contact';

  const header = formLandingStyle ? (
    <HeaderAndForm
      headerDescription={pageContext.pageHeaderData.headerDescription}
      headerSubtitle={pageContext.pageHeaderData.headerSubtitle}
      headerTitle={pageContext.pageHeaderData.headerTitle}
      heroLogoId={pageContext.heroLogo.contentful_id}
    />
  ) : (
    <>
      <ImgContainer>
        <Image
          alt="people working in NaNLABS"
          fluid
          width="100%"
          fromContentful={!isMobile}
          contentful_id={!isMobile && pageContext.heroLogo.contentful_id}
          partialPathName={isMobile && 'res-landing-page'}
        />
      </ImgContainer>
      <MainContainer>
        <PageHeaderContainer>
          <PageHeaderTextContainer>
            <PageHeaderSubtitle>
              {documentToReactComponents(pageContext.pageHeaderData.headerTitle.json)}
            </PageHeaderSubtitle>
            <PageHeaderDescription>
              {renderLineBreak(pageContext.pageHeaderData.headerDescription.headerDescription)}
            </PageHeaderDescription>
            <ButtonGradient
              to={
                pageContext.pageHeaderData.metatagTitle === 'AWS Infrastructure'
                  ? 'https://meetings.hubspot.com/nanlabs/aws-architecture-review'
                  : '/contact'
              }
              title={pageContext.pageHeaderData.ctaCopy}
              ariaLabel="link to contact"
              variant="light"
            />
          </PageHeaderTextContainer>
        </PageHeaderContainer>
      </MainContainer>
    </>
  );

  return (
    <Layout showMenu={showMenu} whiteNaNLogo={showMenu} isHome={!formLandingStyle} bodyType="xWide">
      <MetaTags
        title={pageContext.pageHeaderData.metatagTitle}
        description={pageContext.pageHeaderData.metatagDescription}
        charSet="utf-8"
        image={`https:${pageContext.pageHeaderData.metatagImage.file.url}`}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />
      {pageContext.faq && <FaqsTag list={faqList} />}
      <PageSection
        background={formLandingStyle ? theme.newTheme.color.white : pageContext.heroBackground}
        containerSize="fluid"
      >
        {header}
      </PageSection>

      <PageSection
        containerSize={formLandingStyle ? 'sm' : 's'}
        padding={`0 0 ${theme.newTheme.spacing(13)}`}
      >
        <RichTextWrapper
          color={theme.newTheme.color.primary.main}
          json={{
            data: pageContext.body.json.data,
            content: pageContext.body.json.content,
            nodeType: pageContext.body.json.nodeType,
          }}
          options={richTextOptions}
          firstLetter="normal"
        />
      </PageSection>
      {pageContext.faq && (
        <PageSection containerSize="s" padding={`0 0 ${theme.newTheme.spacing(20)} 0`}>
          <FaqSection faqList={faqList} title={pageContext.faq.title} />
        </PageSection>
      )}

      <LetUsAboutSection
        title={pageContext.cta.bottomCtaTitle}
        buttonTitle={pageContext.cta.cta.text}
        subtitle={pageContext.cta.bottomCtaText.json}
        background={pageContext.heroBackground}
        scrollToForm={pageContext.layout && pageContext.layout.includes('scroll')}
      />
    </Layout>
  );
};

export default landingPage;
