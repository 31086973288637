import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Image from 'core/image';

import PageHeader from 'atomic-design/atoms/page-header';
import ThankYou from 'atomic-design/atoms/thank-you';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderLineBreak } from 'common/string-helpers';
import MainContainer from 'atomic-design/atoms/main-container';
import useIsMobile from 'hooks/useIsMobile';
import PageSection from 'atomic-design/atoms/page-section';
import { graphql, useStaticQuery } from 'gatsby';
import {
  BoxForm,
  BoxFormContainer,
  ImgContainer,
  TitleWrapper,
  Header,
  ButtonContainer,
} from './styles';
import ShortContactForm from 'atomic-design/organisms/short-contact-form';
import useScrollToForm from 'hooks/UseScrollToForm';
import BasicCallToAction from 'atomic-design/molecules/call-to-action/basic-call-to-action';

const HeaderAndForm = ({ headerDescription, headerSubtitle, headerTitle, heroLogoId }) => {
  const [showThankYou, setShowThankYou] = useState(false);
  const isMobile = useIsMobile('mobile');
  const data = useStaticQuery(graphql`
    query {
      contentfulGeneralSettings {
        childContentfulGeneralSettingsContactPageJsonNode {
          emaildata {
            emails {
              email
            }
          }
        }
      }
    }
  `);
  const contactData =
    data.contentfulGeneralSettings.childContentfulGeneralSettingsContactPageJsonNode;
  const scrollToContactForm = useScrollToForm('form[name="contact"]');
  return (
    <>
      <Header>
        <MainContainer>
          <TitleWrapper>
            <PageHeader
              title={headerSubtitle}
              subTitle={documentToReactComponents(headerTitle.json)}
              description={renderLineBreak(headerDescription.headerDescription)}
            />
            <ButtonContainer>
              <BasicCallToAction
                onclick={() => scrollToContactForm()}
                buttonTitle={"Let's get started!"}
              />
            </ButtonContainer>
          </TitleWrapper>
        </MainContainer>
      </Header>
      <PageSection containerSize="fluid">
        <ImgContainer>
          <Image
            alt="people working in NaNLABS"
            fluid
            width="100%"
            fromContentful
            contentful_id={heroLogoId}
            padding="0"
            tabletLandscapeWidth="inherit"
            mobileHeight="300px"
            smallMobileWidth="inherit"
            smallMobileHeight="300px"
            height="inherit"
          />
        </ImgContainer>
        <MainContainer>
          <BoxFormContainer>
            <BoxForm>
              {!showThankYou && (
                <ShortContactForm
                  onSubmit={() => setShowThankYou(true)}
                  contactEmail={contactData?.emaildata?.emails[0]?.email}
                />
              )}
              {showThankYou && (
                <ThankYou
                  title="We’ll be in touch soon."
                  coloredTitle="Thank you!"
                  subtitle="Meanwhile, you may want to look around."
                  textButton="See what we’ve been up to"
                  to="/work"
                />
              )}
            </BoxForm>
          </BoxFormContainer>
        </MainContainer>
      </PageSection>
    </>
  );
};

HeaderAndForm.propTypes = {
  headerDescription: PropTypes.any,
  headerSubtitle: PropTypes.string,
  headerTitle: PropTypes.any,
  heroLogoId: PropTypes.any,
};

export default HeaderAndForm;
